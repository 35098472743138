import { get, update, IApiResponseExtended } from '~/services/http-requests'
import { ILimitOffsetParams } from '~/interfaces/interface-utils'

const _BS_BEATS = 'beats'

/*
 **** GET ****
 */
export function fetchBeats({ limit = null, offset = null }: ILimitOffsetParams): Promise<IApiResponseExtended<IBeatProducer[]>> {
    return get({ baseProperty: _BS_BEATS, fullUrl: `${_BS_BEATS}.json?limit=${limit}&offset=${offset}` })
}

/*
 **** PATCH ****
 */
export function updateBeat(beat: Partial<IBeatProducer>) {
    return update({ baseProperty: _BS_BEATS, fullUrl: `${_BS_BEATS}/${beat.id}/`, dispatchParams: beat })
}
