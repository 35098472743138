






















import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import { updateBeat } from '#/api/beat'
import { PlayerStore } from '~/store/player'

@Component
export default class BeatTableItem extends Vue {
	// @Prop({ type: Object }) beat: IBeatProducer
	@Prop({ type: Number }) index: number
    @Prop({ type: Array }) beatPlaylist: IBeatProducer[]

    @PlayerStore.State('isAudioPaused') isAudioPaused: boolean
    @PlayerStore.Getter('beatPlaying') beatPlaying: IBeat

    get beat(): IBeatProducer {
		return this.beatPlaylist[this.index]
    }

	get showHideInMarketplace() {
		return this.beat.is_shown_in_marketplace_homepage
	}
	// patch function to update value
	set showHideInMarketplace(value) {
		updateBeat({ id: this.beat.id, is_shown_in_marketplace_homepage: value })
	}

	get audioClasses() {
        return {
            playing: !this.isAudioPaused && this.beat === this.beatPlaying,
            paused: this.isAudioPaused && this.beat === this.beatPlaying
        }
    }

	togglePlayStatus() {
        const dispatchParams = { beats: this.beatPlaylist, index: this.index, route: this.$route.fullPath }
        // then we call the togglePlayStatus passing the right params
        this.$store.dispatch('player/togglePlayStatus', dispatchParams)
    }
}
