





























import { Vue, Prop, Component } from 'vue-property-decorator'

import { NUM_OF_BEATS_PER_PAGE } from '#/store/beat'

import BeatTableItem from '@admin/components/BeatTableItem.vue'
import VLoadSpinner from '~/components/VLoadSpinner.vue'
import Pagination from '#/components/Pagination.vue'
import { fetchBeats } from '@admin/api/beat'
import { ILimitOffsetParams } from '~/interfaces/interface-utils'

@Component({ components: { BeatTableItem, VLoadSpinner, Pagination } })
export default class NewUploadsPage extends Vue {
	@Prop({ type: Number }) pageIndex: number

	isLoading = true
	numberOfPages = 1
	beats: IBeatProducer[] = []

	async fetchBeats({ limit, offset }: ILimitOffsetParams) {
		this.isLoading = true
		try {
			const data = await fetchBeats({ limit, offset })
			// grab the number of pages only on first fetch
			if (data.count) {
				let numberOfPages = Math.trunc(data.count / NUM_OF_BEATS_PER_PAGE)
				// if the number isn't even, add 1 page to show the remaining beats
				if (data.count > NUM_OF_BEATS_PER_PAGE && data.count % NUM_OF_BEATS_PER_PAGE !== 0) numberOfPages++
				this.numberOfPages = numberOfPages
			}
			this.beats = data.results
		} finally {
			this.isLoading = false
		}
	}

	fetchNewBeats(offset: number, pageIndex: number) {
		this.fetchBeats({ limit: NUM_OF_BEATS_PER_PAGE, offset })
		this.$router.push({ name: 'NewUploads', params: { pageIndex: pageIndex.toString() } })
	}

	created() {
		this.fetchBeats({ limit: NUM_OF_BEATS_PER_PAGE, offset: (this.pageIndex - 1) * NUM_OF_BEATS_PER_PAGE })
	}
}
